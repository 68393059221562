import logo from './logo.svg';
import './App.css';
import RouterCustom from './RouterCustom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import CameraIcon from '@mui/icons-material/PhotoCamera';

import {
  BrowserRouter
} from "react-router-dom";
import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="">
      Ballettschule Felicitas Grell
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

//https://mui.com/material-ui/customization/palette/
const theme = createTheme({
  palette: {
    primary: {
      main: "#EC9BD9"
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          
          <Link color="inherit" href="../">
            <Typography variant="h6" color="inherit" textAlign={'center'} noWrap>
              Ballettschule Felicitas Grell
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <BrowserRouter>
        <Container maxWidth="lg">
          <RouterCustom></RouterCustom>
        </Container>
      </BrowserRouter>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}

export default App;
