import React, { Suspense } from 'react';

import {
    Routes,
    Route,
} from "react-router-dom";

import menuconfig from './menuconfig.js';


export default function RouterCustom() {
    const fallback = <div> Loading... </div>;

    return (
        <Suspense fallback={fallback}>
            <Routes>
                {menuconfig.map(({ path, Component, children }, i) => {
                    if (children) {
                        const childs = children.map(({ path, Component, children }, i) => {
                            return <Route exact key={i} path={path} element={<Component />} />
                        });
                        return childs;
                    }

                    return <Route exact key={i} path={path} element={<Component />} />
                }
                )}
            </Routes>
        </Suspense>);
}