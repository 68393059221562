import React from 'react';

const  menuconfig = [
    { 'id': "Ballett",'path': "/", Component: React.lazy(() => import( "./Sites/home")), 'privat': false, showOnBigPage: true  },
    { 'id': "BallettInfo",'path': "/ballettinfo", Component: React.lazy(() => import( "./Sites/ballettInfo")), 'privat': false, showOnBigPage: true  },
    { 'id': "Pictures",'path': "/pictures", Component: React.lazy(() => import( "./Sites/pictures")), 'privat': false, showOnBigPage: true  },
    { 'id': "Unterricht",'path': "/unterricht", Component: React.lazy(() => import( "./Sites/unterricht")), 'privat': false, showOnBigPage: true  },
    { 'id': "Kontakt",'path': "/kontakt", Component: React.lazy(() => import( "./Sites/kontakt")), 'privat': false, showOnBigPage: true  },
    { 'id': "Aktuelles",'path': "/aktuelles", Component: React.lazy(() => import( "./Sites/aktuelles")), 'privat': false, showOnBigPage: true  },
    { 'id': "Impressum",'path': "/impressum", Component: React.lazy(() => import( "./Sites/impressum")), 'privat': false, showOnBigPage: true  },
    /*{ 'id': "Über uns", 'privat': false, showOnBigPage: true, children: [
        { 'id': "Team", 'path': "/aboutus" , Component: React.lazy(() => import( "../Pages/aboutus")), 'privat': false , showOnBigPage: true },
        { 'id': "Karriere", 'path': "/jobstart" , Component: React.lazy(() => import( "../Pages/jobstart")), 'privat': false, showOnBigPage: true  }
    ]},*/
    //{ 'id': "Garagenausstattung",'path': "/garagezubehor", Component: React.lazy(() => import( "../Pages/garagezubehor")), 'privat': false   },
    //{ 'id': "Shop",'path': "/shop", Component: React.lazy(() => import( "../Pages/shop")) , 'privat': false, showOnBigPage: true },
    
    //{ 'id': "Datenschutz", 'path': "/datenschutz" , Component: React.lazy(() => import( "../Pages/datenschutz")), 'privat': false, onlyroute: false, showOnBigPage: false },

    //{ 'id': "contactform", 'path': "/contactform" , Component: React.lazy(() => import( "../Pages/contactform")), 'privat': false, onlyroute: true  },
    //{ 'id': "testpage", 'path': "/testpage" , Component: React.lazy(() => import( "../Pages/testpage")), 'privat': false, onlyroute: true  },
    //{ 'id': "impressum", 'path': "/impressum" , Component: React.lazy(() => import( "../Pages/impressum")), 'privat': false, onlyroute: true  },
    
    
];

export default menuconfig;
